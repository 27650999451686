<template>
  <div>
    <vl-region mod-no-space-top>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column>
            <vl-title class="vl-u-spacer--none">{{ $t('products.label') }}</vl-title>
            <vl-data-table>
              <thead>
                <tr>
                  <th v-for="(column, index) in columns" :key="index">
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in products" :key="index">
                  <td>
                    {{ product.name }}
                  </td>
                  <td>
                    {{ product.titleCode }}
                  </td>
                  <td>€{{ product.amount }}</td>
                  <td v-if="organization.orafin">
                    {{ product.ipdc }}
                  </td>
                  <td v-if="organization.orafin">
                    {{ product.orafinSourceName }}
                  </td>
                  <td v-if="organization.orafin">{{ product.boekhoudSleutelParticulier }}</td>
                  <td v-if="organization.orafin">{{ product.boekhoudSleutelProfessioneel }}</td>

                  <td style="width: 18%;">
                    <button
                      @click="edit(index)"
                      class="btn waves-effect waves-light yellow darken-2"
                    >
                      <i class="material-icons">edit</i>
                    </button>
                    <button
                      @click="deleteProduct(index)"
                      class="btn waves-effect waves-light red darken-2"
                    >
                      <i class="material-icons">delete</i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colspan="1">
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input ref="name" v-model="input.name" id="name" type="text" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input v-model="input.titleCode" id="titleCode" type="text" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input v-model="input.amount" id="amount" type="number" />
                      </div>
                    </div>
                  </td>
                  <td v-if="organization.orafin" colspan="1">
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input ref="ipdc" v-model="input.ipdc" id="ipdc" type="text" />
                      </div>
                    </div>
                  </td>
                  <td v-if="organization.orafin">
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input v-model="input.orafinSourceName" id="orafinSourceName" type="text" />
                      </div>
                    </div>
                  </td>
                  <td v-if="organization.orafin">
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input
                          v-model="input.boekhoudSleutelParticulier"
                          id="boekhoudSleutelParticulier"
                          type="text"
                        />
                      </div>
                    </div>
                  </td>
                  <td v-if="organization.orafin">
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input
                          v-model="input.boekhoudSleutelProfessioneel"
                          id="boekhoudSleutelProfessioneel"
                          type="text"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <button @click="add" class="btn btn-waves green darken-2">
                      <i class="material-icons">add</i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </vl-data-table>
            <div
              id="modal"
              class="modal fade"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="center-align">{{ $t('products.modal.title') }}</h4>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <form class="col s12">
                        <div class="row">
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="name">{{ $t('products.modal.name') }}</label>
                              <input
                                id="name"
                                class="form-control"
                                type="text"
                                v-model="editInput.name"
                              />
                            </div>
                          </div>
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="title_code">{{ $t('products.modal.titleCode') }}</label>
                              <input
                                id="title_code"
                                class="form-control"
                                type="text"
                                v-model="editInput.titleCode"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="amount">{{ $t('products.modal.amount') }}</label>
                              <input
                                id="amount"
                                class="form-control"
                                type="number"
                                v-model="editInput.amount"
                              />
                            </div>
                          </div>
                          <div class="col s6"></div>
                        </div>
                        <div v-if="organization.orafin" class="row">
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="IPDC">{{ $t('products.modal.IPDC') }}</label>
                              <input
                                id="IPDC"
                                class="form-control"
                                type="text"
                                v-model="editInput.ipdc"
                              />
                            </div>
                          </div>
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="orafinSourceName">{{
                                $t('products.modal.orafinSourceName')
                              }}</label>
                              <input
                                id="orafinSourceName"
                                class="form-control"
                                type="text"
                                v-model="editInput.orafinSourceName"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-if="organization.orafin" class="row">
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="boekhoudSleutelProfessioneel">{{
                                $t('products.modal.boekhoudSleutelProfessioneel')
                              }}</label>
                              <input
                                id="boekhoudSleutelProfessioneel"
                                class="form-control"
                                type="text"
                                v-model="editInput.boekhoudSleutelProfessioneel"
                              />
                            </div>
                          </div>
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="boekhoudSleutelParticulier">{{
                                $t('products.modal.boekhoudSleutelParticulier')
                              }}</label>
                              <input
                                id="boekhoudSleutelParticulier"
                                class="form-control"
                                type="text"
                                v-model="editInput.boekhoudSleutelParticulier"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                      {{ $t('products.modal.close') }}
                    </button>
                    <button
                      @click="update"
                      type="button"
                      class="btn btn-primary"
                      data-dismiss="modal"
                    >
                      {{ $t('products.modal.update') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </vl-column>
        </vl-grid>
      </vl-layout>
    </vl-region>
  </div>
</template>
<script>
import $ from 'jquery';
export default {
  async created() {
    try {
      const user = this.$store.getters['app/user'];
      this.$store.dispatch('organization/get', user.organization.id).then(async response => {
        this.organization = response;
        if (this.organization.orafin) {
          this.columns = [
            ...this.columns,
            'IPDC',
            'Source Name',
            'Boekhoud Sleutel Particulier',
            'Boekhoud Sleutel Professioneel',
          ];
        }
        this.products = await this.$store.dispatch('product/getByTenant');
      });
    } catch (err) {
      this.error = err.message;
    }
  },
  data() {
    return {
      organization: {},
      organizationId: '',
      columns: ['Naam', 'Titel code', 'Hoeveelheid (€)'],
      selectedIndex: 0,
      products: [],
      bin: [],
      input: {
        name: 'civiele test Combi',
        titleCode: 'CCT1',
        amount: 180,
        ipdc: 'test',
        orafinSourceName: 'test',
        boekhoudSleutelParticulier: 'test',
        boekhoudSleutelProfessioneel: 'test',
      },
      editInput: {
        name: '',
        titleCode: '',
        amount: 0,
        ipdc: '',
        orafinSourceName: '',
        boekhoudSleutelParticulier: '',
        boekhoudSleutelProfessioneel: '',
      },
    };
  },
  methods: {
    //function to add data to table
    add: function() {
      try {
        this.$store.dispatch('product/add', this.input).then(response => {
          this.products.push(response);
          for (const key in this.input) {
            this.input[key] = '';
          }
        });
      } catch (err) {
        this.error = err.message;
      }

      this.$refs.name.focus();
    },
    //function to handle data edition
    edit: function(index) {
      this.editInput = { ...this.products[index] };
      this.selectedIndex = index;
      $('#modal').modal();
    },

    //function to update data
    update: function() {
      this.products.splice(this.selectedIndex, 1);
      try {
        this.$store.dispatch('product/update', this.editInput).then(response => {
          this.products.push(response);
          for (const key in this.editInput) {
            this.editInput[key] = '';
          }
        });
      } catch (err) {
        this.error = err.message;
      }
    },
    //function to defintely delete data
    deleteProduct: function(index) {
      if (confirm('Do you really want to delete?')) {
        try {
          this.$store.dispatch('product/delete', this.products[index].id).then(() => {
            this.products.splice(index, 1);
          });
        } catch (err) {
          this.error = err.message;
        }
      }
    },
  },
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.css';
p,
button,
label,
input {
  font-size: 1.5rem;
}
</style>
